.hero-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-sub-container {
    margin: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-title {
    flex: 1
}

.hero-subtitle {
    flex: 1
}

.hero-description {
    flex: 1;
    color: gray;
    cursor: pointer;
    z-index: 999;

    &:hover {
        color: black;
        
    }
}

.hero-srolldown-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.hero-srolldown-wrapper {
    position: relative;
    bottom: -90%;
    left: 20px;
}

@keyframes slideInFromLeft {
    0% {
        color: white;
    }
    15% {
        color: white;
    }
    50% {
        color: black;
    }
    100% {
        color: rgba(255, 255, 255, 0);
    }
}

.hero-srolldown-text {
    animation: 8s ease-out 0s 1 slideInFromLeft;
    
    color: rgba(255, 255, 255, 0);
    text-align: center;
    width: 210px;
    padding: 5px;

    transition: all ease-in-out 1.0s;

    &:hover {
        transition: all ease-in-out 0.2s;
        color: black;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
}




.single-project-container {
    margin: 50px;
    padding: 25px;
    /* min-width: 45%; */
    height: 75%;
    background-color: #323232;
    color: white;
    transition: all ease-in-out 1.0s;

    &:hover {
        transition: all ease-in-out 0.2s;
        background-color: #F8F8F8;
        color: #323232;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        cursor: pointer;
        border-radius: 25px;
    }
}

.single-project-title {

}

.single-project-subtitle {

}

.single-project-description {

}


.section-title {
    margin: 50px;
}

.grid-container {
    display: grid;
    /* Use display property as grid*/
    gap: 5px;
    grid-template-columns: repeat(
        auto-fit, minmax(35%, 1fr));
}


.contact-container {
    margin: 50px;
    margin-top: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.social-icon {
    margin: 2%;
}

.social-img {
    border-radius: 50%;
    transition: all ease-in-out 1.0s;

    &:hover {
        transition: all ease-in-out 0.2s;
        box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        cursor: pointer;
    }
}